
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import { GenderDistribution } from "../../models/reports/genderdistribution";
import { formatTwoDecimal, formatFinancialAmounts } from '../../utils/format'

export default Vue.extend({
  name: "GenderDistributionLatest",
  data: () => ({
    genderData: [] as GenderDistribution[],
    isLoading: true,
  }),
  computed: {
    selectedSurvey() {
      return this.$store.getters["agsreport/getYearSelected"];
    },
    headers() {
      return [
        {
          text: "N",
          value: "surveyYear",
        },
        {
          text: i18n.t("reports.agsreports.yearselected"),
          value: "surveyYear",
        },
        {
          text: i18n.t("reports.agsreports.disorders"),
          value: "disorders",
        },
        {
          text: i18n.t("reports.agsreports.countries"),
          value: "countries",
        },
        {
          text: `${i18n.t("datamanager.patients.total")} ${i18n.t(
            "datamanager.patients.label"
          )}`,
          value: "totalPatients",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: i18n.t("datamanager.patients.male"),
          value: "maleCount",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t("datamanager.patients.male")} %`,
          value: "malePercent",
          formatter: (val:number):number|string  => formatTwoDecimal(val)
        },
        {
          text: i18n.t("datamanager.patients.female"),
          value: "femaleCount",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t("datamanager.patients.female")} %`,
          value: "femalePercent",
          formatter: (val:number):number|string  => formatTwoDecimal(val)
        },
        {
          text: i18n.t("basic.choices.notknown"),
          value: "unknownCount",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t("basic.choices.notknown")} %`,
          value: "unknownPercent",
          formatter: (val:number):number|string  => formatTwoDecimal(val)
        },
      ];
    },
  },
  methods: {
    getGenderData(year: number) {
      this.isLoading = true;
      axios
        .get(`Report/WFH/GenderDistribution?year=${year}&isLatestData=${true}`)
        .then((res) => {
          this.genderData = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      axios
        .get(
          `Report/WFH/GenderDistribution/export?year=${
            this.selectedSurvey.year
          }&isLatestData=${true}`,
          { responseType: "blob" }
        )
        .then((res) => {
          const fileName = `${i18n.t(
            "navbar.menu.reportsub.ags_reports"
          )} - ${i18n.t("reports.agsreports.genderdistribution")} - ${i18n.t(
            "reports.agsreports.latestdata"
          )}`;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "aplication/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    if (this.selectedSurvey.year) {
      this.getGenderData(this.selectedSurvey.year);
    }
  },
  watch: {
    selectedSurvey() {
      this.getGenderData(this.selectedSurvey.year);
    },
  },
});
